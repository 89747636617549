<template>
    <base-form-field-api-select models-name="tipologie"
                                :block-auto-start="!Boolean(idTipologia)"
                                disable-sort
                                v-model="tipologia"
                                :item-text="t => getNome(t)"
                                label="Tipologia"
                                placeholder="Seleziona una tipologia"
                                disable-disabled
                                @input="$emit('input', $event)"
                                @finish.once="onFinish"
    >

        <template #item="{ item }">
            {{ getNome(item) }}
        </template>

        <template #selection="{ item }">
            {{ getNome(item) }}
        </template>

    </base-form-field-api-select>
</template>

<script>
    import BaseFormFieldApiSelect from '@/components/aaaProject/form/BaseFormFieldApiSelect';

    export default {
        name: 'BaseSelectTipologie',
        components: {BaseFormFieldApiSelect},
        props: {idLinguaFk: Number},
        data: () => ({
            tipologia: undefined
        }),
        computed: {
            idTipologia: function () {
                return /^\d+$/.test(this.$route.query.tpl) ? Number(this.$route.query.tpl) : false;
            }
        },
        methods: {
            getNome(tipologia) {

                let testo = tipologia.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = tipologia.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = tipologia.testi[0];
                }

                return testo.NOME + ' (' + testo.LNG_SIGLA + ')';

            },
            onFinish(response) {
                if (this.idTipologia) {
                    this.tipologia = response.items.find(t => t.ID_REC === this.idTipologia);
                }
            }
        }
    }
</script>

<style scoped>

</style>
