var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"eventi","model-ref":"l'evento","new-msg":"un nuovo evento","is-loading":_vm.isLoading,"headers":_vm.headers,"can-create":_vm.$store.state.authModule.utente.CAN_CREATE_EVENTO},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('eventi-table-header',{attrs:{"id-lingua-fk":_vm.idLinguaFk},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"IS_PERCORSO",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("fa fa-"+_vm._s(!item.IS_PERCORSO ? 'calendar-alt' : 'route'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(!item.IS_PERCORSO ? 'EVENTO' : 'PERCORSO'))])])]}},{key:"TITOLO",fn:function(ref){
var item = ref.item;
return [(!!_vm.getTesto(item).TITOLO)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({},on),[_vm._v(_vm._s(_vm.getTesto(item).TITOLO)+" ("),_c('strong',[_vm._v(_vm._s(_vm.getTesto(item).LNG_SIGLA))]),_vm._v(")")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTesto(item).DETTAGLIO || 'No data available'))])]):_c('span',[_vm._v("-")])]}},{key:"DATA_INIZIO",fn:function(ref){
var item = ref.item;
return [(item.DURATA === 'STATICO')?_c('i',[_vm._v("Sempre disponibile")]):(item.DURATA === 'SINGOLO')?_c('i',[_vm._v(_vm._s(_vm.getData(item.DATA_INIZIO, 'L [dalle] LTS')))]):_c('i',[_vm._v(_vm._s('dal ' + _vm.getData(item.DATA_INIZIO) + ' al ' + _vm.getData(item.DATA_FINE)))])]}},{key:"CT_DENOMINAZIONE",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getTestoCitta(item).DENOMINAZIONE)+" ("+_vm._s(item.CT_SIGLA)+")")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.INDIRIZZO))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }