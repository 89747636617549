import { render, staticRenderFns } from "./BaseAutocompleteCitta.vue?vue&type=template&id=52bd62ac&scoped=true&"
import script from "./BaseAutocompleteCitta.vue?vue&type=script&lang=js&"
export * from "./BaseAutocompleteCitta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bd62ac",
  null
  
)

export default component.exports