<template>
    <base-table models-name="eventi"
                model-ref="l'evento"
                new-msg="un nuovo evento"
                :is-loading="isLoading"
                :headers="headers"
                :can-create="$store.state.authModule.utente.CAN_CREATE_EVENTO"
                @changeOptions="onChangeOptions($event)"
    >

        <template #header="{ onChangeFilter }">
            <eventi-table-header :id-lingua-fk="idLinguaFk"
                                 @changeOptions="onChangeOptions($event)"
                                 @changeFilter="onChangeFilter"
            ></eventi-table-header>
        </template>

        <template #IS_PERCORSO="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>fa fa-{{ !item.IS_PERCORSO ? 'calendar-alt' : 'route' }}</v-icon>
                </template>
                <span>{{ !item.IS_PERCORSO ? 'EVENTO' : 'PERCORSO' }}</span>
            </v-tooltip>
        </template>

        <template #TITOLO="{ item }">
            <v-tooltip v-if="!!getTesto(item).TITOLO" bottom>
                <template v-slot:activator="{ on }">
                    <i v-on="on">{{ getTesto(item).TITOLO }} (<strong>{{ getTesto(item).LNG_SIGLA }}</strong>)</i>
                </template>
                <span>{{ getTesto(item).DETTAGLIO || 'No data available' }}</span>
            </v-tooltip>
            <span v-else>-</span>
        </template>

        <template #DATA_INIZIO="{ item }">
            <i v-if="item.DURATA === 'STATICO'">Sempre disponibile</i>
            <i v-else-if="item.DURATA === 'SINGOLO'">{{ getData(item.DATA_INIZIO, 'L [dalle] LTS') }}</i>
            <i v-else>{{ 'dal ' + getData(item.DATA_INIZIO) + ' al ' + getData(item.DATA_FINE) }}</i>
        </template>

        <template #CT_DENOMINAZIONE="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <span v-on="on">{{ getTestoCitta(item).DENOMINAZIONE }} ({{ item.CT_SIGLA }})</span>
                </template>
                <span>{{ item.INDIRIZZO }}</span>
            </v-tooltip>
        </template>

    </base-table>
</template>

<script>
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import EventiTableHeader from '@/components/secure/eventi/components/EventiTableHeader';
    import moment from '@/moment';

    export default {
        name: 'EventiTable',
        components: {EventiTableHeader, BaseTable},
        props: {isLoading: Boolean, idLinguaFk: Number},
        data: () => ({
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: 'Stato ',
                    value: 'ERRORE',
                    align: 'center'
                },
                {
                    text: 'Tipo ',
                    value: 'IS_PERCORSO',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Taglia ',
                    value: 'TAGLIA',
                    align: 'center'
                },
                {
                    text: 'Titolo ',
                    value: 'TITOLO',
                    align: 'center',
                    sortable: false,
                    custom: true
                },
                {
                    text: 'Data ',
                    value: 'DATA_INIZIO',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Città ',
                    value: 'CT_DENOMINAZIONE',
                    align: 'center',
                    sortable: false,
                    custom: true
                },
                {
                    text: 'Visualizzazioni ',
                    value: 'VISUALIZZAZIONI',
                    align: 'center'
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getTesto(evento) {

                if (!evento.testi || evento.testi.length <= 0) {
                    return {};
                }

                let testo = evento.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = evento.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = evento.testi[0];
                }

                return testo;

            },
            getTestoCitta(evento) {

                let testo = evento.citta.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = evento.citta.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = evento.citta[0];
                }

                return testo;

            },
            getData(data, format) {
                return moment.toDate(data, !format ? 'L' : format);
            }
        }
    }
</script>

<style scoped>

</style>
