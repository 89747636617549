var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h-row',[_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Tipo","key-options":"isPercorso","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: 'EVENTO', value: false},
                                      {label: 'PERCORSO', value: true}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Stato","key-options":"errore","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: 'ATTIVO', value: 'NONE'},
                                      {label: 'INATTIVO', value: 'NOT_NONE'},
                                      {label: 'DISATTIVATO MANUALMENTE', value: 'SELF'},
                                      {label: 'TIPOLOGIA INATTIVA', value: 'TIPOLOGIA_OFF'},
                                      {label: 'CLIENTE INATTIVO', value: 'CLIENTE_OFF'},
                                      {label: 'NON ANCORA INIZIATO', value: 'NO_STARTED'},
                                      {label: 'EVENTO TERMINATO', value: 'EXPIRED'},
                                      {label: 'TESTO DEFAULT MANCANTE', value: 'NO_TEXT_DEFAULT'},
                                      {label: 'COVER MANCANTE', value: 'NO_COVER'},
                                      {label: 'GPX MANCANTE', value: 'NO_GPX'},
                                      {label: 'GALLERIE DA CORREGGERE', value: 'SOME_GALLERIA_ERRATA'}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-select-tipologie',{attrs:{"id-lingua-fk":_vm.idLinguaFk},on:{"input":function($event){return _vm.$emit('changeOptions', {idTipologiaFk: _vm.tipologia ? _vm.tipologia.ID_REC : null})}},model:{value:(_vm.tipologia),callback:function ($$v) {_vm.tipologia=$$v},expression:"tipologia"}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-autocomplete-citta',{on:{"input":function($event){return _vm.$emit('changeOptions', {idCittaFk: _vm.citta ? _vm.citta.ID_REC : null})}},model:{value:(_vm.citta),callback:function ($$v) {_vm.citta=$$v},expression:"citta"}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-select-clienti',{on:{"input":function($event){return _vm.$emit('changeOptions', {idClienteFk: _vm.cliente ? _vm.cliente.ID_REC : null})}},model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Tesori Nascosti","key-options":"isSuggerito","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: 'SI', value: true},
                                      {label: 'NO', value: false}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12"}},[_c('base-table-header-filter',{attrs:{"exact":""},on:{"changeFilter":function($event){return _vm.$emit('changeFilter', $event)},"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }