<template>
    <h-row>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Tipo"
                                      key-options="isPercorso"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'EVENTO', value: false},
                                          {label: 'PERCORSO', value: true}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Stato"
                                      key-options="errore"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'ATTIVO', value: 'NONE'},
                                          {label: 'INATTIVO', value: 'NOT_NONE'},
                                          {label: 'DISATTIVATO MANUALMENTE', value: 'SELF'},
                                          {label: 'TIPOLOGIA INATTIVA', value: 'TIPOLOGIA_OFF'},
                                          {label: 'CLIENTE INATTIVO', value: 'CLIENTE_OFF'},
                                          {label: 'NON ANCORA INIZIATO', value: 'NO_STARTED'},
                                          {label: 'EVENTO TERMINATO', value: 'EXPIRED'},
                                          {label: 'TESTO DEFAULT MANCANTE', value: 'NO_TEXT_DEFAULT'},
                                          {label: 'COVER MANCANTE', value: 'NO_COVER'},
                                          {label: 'GPX MANCANTE', value: 'NO_GPX'},
                                          {label: 'GALLERIE DA CORREGGERE', value: 'SOME_GALLERIA_ERRATA'}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12" sm="6">
            <base-select-tipologie v-model="tipologia"
                                   :id-lingua-fk="idLinguaFk"
                                   @input="$emit('changeOptions', {idTipologiaFk: tipologia ? tipologia.ID_REC : null})"
            ></base-select-tipologie>
        </h-col>

        <h-col cols="12" sm="6">
            <base-autocomplete-citta v-model="citta"
                                     @input="$emit('changeOptions', {idCittaFk: citta ? citta.ID_REC : null})"
            ></base-autocomplete-citta>
        </h-col>

        <h-col cols="12" sm="6">
            <base-select-clienti v-model="cliente"
                                 @input="$emit('changeOptions', {idClienteFk: cliente ? cliente.ID_REC : null})"
            ></base-select-clienti>
        </h-col>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Tesori Nascosti"
                                      key-options="isSuggerito"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'SI', value: true},
                                          {label: 'NO', value: false}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12">
            <base-table-header-filter exact
                                      @changeFilter="$emit('changeFilter', $event)"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-filter>
        </h-col>

    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTableHeaderSelect from '@/components/aaaProject/table/BaseTableHeaderSelect';
    import BaseTableHeaderFilter from '@/components/aaaProject/table/BaseTableHeaderFilter';
    import BaseSelectTipologie from '@/components/aaaProject/table/api/BaseSelectTipologie';
    import BaseAutocompleteCitta from "@/components/aaaProject/table/api/BaseAutocompleteCitta";
    import BaseSelectClienti from "@/components/aaaProject/table/api/BaseSelectClienti";

    export default {
        name: 'EventiTableHeader',
        components: {
            HRow,
            HCol,
            BaseTableHeaderFilter,
            BaseTableHeaderSelect,
            BaseSelectTipologie,
            BaseAutocompleteCitta,
            BaseSelectClienti
        },
        props: {idLinguaFk: Number},
        data: () => ({
            tipologia: undefined,
            citta: undefined,
            cliente: undefined
        })
    }
</script>

<style scoped>

</style>
