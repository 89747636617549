<template>
    <base-form-field-api-select models-name="clienti"
                                :block-auto-start="!Boolean(idCliente)"
                                v-model="cliente"
                                item-text="NOME"
                                label="Cliente"
                                placeholder="Seleziona un cliente"
                                :disabled="!$store.state.authModule.utente.CAN_CLIENTE"
                                :disable-disabled="!!$store.state.authModule.utente.CAN_CLIENTE"
                                @input="$emit('input', $event)"
                                @finish.once="onFinish"
    ></base-form-field-api-select>
</template>

<script>
    import BaseFormFieldApiSelect from '@/components/aaaProject/form/BaseFormFieldApiSelect';

    export default {
        name: 'BaseSelectClienti',
        components: {BaseFormFieldApiSelect},
        data: () => ({
            cliente: undefined
        }),
        computed: {
            idCliente: function () {
                return /^\d+$/.test(this.$route.query.cln) ? Number(this.$route.query.cln) : false;
            }
        },
        methods: {
            onFinish(response) {
                if (this.idCliente) {
                    this.cliente = response.items.find(c => c.ID_REC === this.idCliente);
                }
            }
        }
    }
</script>

<style scoped>

</style>
