<template>
    <base-form-field-api-autocomplete models-name="citta"
                                      :auto-start="Boolean(idCitta)"
                                      filter-start="DENOMINAZIONE"
                                      v-model="citta"
                                      :item-text="c => getNome(c)"
                                      label="Citta"
                                      placeholder="Digita per iniziare la ricerca"
                                      disable-disabled
                                      @input="$emit('input', $event)"
    >

        <template #item="{ item }">
            {{ getNome(item) }}
        </template>

        <template #selection="{ item }">
            {{ getNome(item) }}
        </template>

    </base-form-field-api-autocomplete>
</template>

<script>
    import BaseFormFieldApiAutocomplete from '@/components/aaaProject/form/BaseFormFieldApiAutocomplete';

    export default {
        name: 'BaseAutocompleteCitta',
        components: {BaseFormFieldApiAutocomplete},
        data: () => ({
            citta: undefined
        }),
        computed: {
            idCitta: function () {
                return /^\d+$/.test(this.$route.query.ctt) ? Number(this.$route.query.ctt) : false;
            },
            nmCtt: function () {
                return this.$route.query.nmCtt;
            }
        },
        methods: {
            getNome(citta) {
                return this.getTesto(citta).DENOMINAZIONE + ' ' + citta.SIGLA;
            },
            getTesto(citta) {

                let testo = citta.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = citta.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = citta.testi[0];
                }

                return testo;

            }
        },
        created() {
            if (this.idCitta && this.nmCtt) {
                this.citta = {ID_REC: this.idCitta, DENOMINAZIONE: this.nmCtt, SIGLA: ''}
            }
        }
    }
</script>

<style scoped>

</style>
